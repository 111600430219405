import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cDataService } from 'src/app/services/data.service';
import { MustMatch } from 'src/app/services/must-match.validator';
import { cSessionService } from 'src/app/services/session.service';
import {Location} from '@angular/common';
import { UiService } from 'src/app/services/ui.service';
import { environment } from 'src/environments/environment';
interface TenantInformation {
  tenantId: string;
  tenantName: string;
}
interface SideNavToggle{
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
@Output() onToggleSidenav : EventEmitter<SideNavToggle> = new EventEmitter();
  @Input() isSideNavCollapsed:any;
  @Output() emitSidenavCollapsed = new EventEmitter()
  
  bShowIcon = false;
  bShowSettingDropdown = false;
  UserDetails : any;
  bShowSearchIcon = false;
  searchResult : string = "";
  searchDocumentResult : string = "";
  showTrashLabel: boolean =false;
  bDisplayErrorBlock:boolean = false;
  changePasswordForm:FormGroup
  changeNameForm:FormGroup;
  loginTenantInformation : TenantInformation = {tenantId : "",tenantName : "",};
  resMessage: { message: any; responseType: any; color: any; };
  closesidebar: any;
  bSplitArea: boolean;
  bDisplayGlobalSearches: boolean =false;
  bDisplayTenantInformation: boolean =false;
  selectedDocumentFolderId: string= "";
  searchWithQNA : boolean = false;
  versionInformation: any;
  public logo: string = "";
  showLogo: boolean=false;
  showEllipses: boolean=false;
  collapsed: boolean= false;
  screenWidth: number;
  userDetails: any;
  environment: any;
  advanceSearch: any;
  constructor(private _location: Location, private cSessionsService:cSessionService,private sUIService:UiService, private oRoute : Router ,private oFormBuilder:FormBuilder, private oDataService : cDataService,private oSessionService : cSessionService,private oModalService : NgbModal){
    this.environment = environment.Theme
  }

  ngOnInit(): void {
    let searchQuery=JSON.parse(localStorage.getItem('searchQuery'))
    if(searchQuery)
    {
      this.searchDocumentResult=searchQuery.search
      this.searchWithQNA=searchQuery.qna
      this.advanceSearch=searchQuery.advanceSearch
    }
    
    this.cHeaderComponent_GetLogo()
    this.bDisplayTenantInformation = this.oSessionService.SessionService_GetApplicationSideBarConfigurationFile().env == 'saas' ? true : false;
    this.oDataService.bDisplyDropdown.subscribe(res => {
     this.bShowIcon = res;
    })

    this.oDataService.bDisplaySettingDropdown.subscribe(res => {
      this.bShowSettingDropdown = res;
     })

     this.oDataService.bShowHeaderSearch.subscribe(res => {
      this.bShowSearchIcon = res;
     })
     this.oDataService.trash.subscribe(value=>{
      this.showTrashLabel=value
    })
     this.UserDetails = this.oSessionService.SessionService_GetUserDetails();

     this.oDataService.closeSidebar.subscribe(res =>{
      this.closesidebar = res
     })

     this.oDataService.globalSearch.subscribe(res =>{
      this.bDisplayGlobalSearches = res.searchBoolan;
      this.selectedDocumentFolderId = res.folderId??'';
     })

     this.oDataService.resetSearch.subscribe(res =>{
      if(res){
        this.searchDocumentResult = '';
        this.searchWithQNA = false
        this.advanceSearch = false
      }
     })
     this.oDataService.bDisplayDocEllipses.subscribe(res =>{
      this.showEllipses = res
     })

     this.oDataService.populateGlobalSeacrhField.subscribe(res=>{
      if(res)
      {
        this.searchDocumentResult= res.searchPhrase
        this.searchWithQNA=res.qNa
        this.advanceSearch=res.advanceSearch
      }
     })
     this.oDataService.updateHeaderLogo.subscribe(res=>{
      if(res!='')
      {
        this.logo=res
      }
     })
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.userDetails = this.cSessionsService.SessionService_GetUserDetails();
    if(this.userDetails?.role =='admin') this.collapsed = this.isSideNavCollapsed;
  }

onSearchDocumentResultChange(searchString){
  if(searchString==''){
    localStorage.removeItem('searchQuery');
  }
  else
  {
    let queryParams = { search : this.searchDocumentResult, qna : this.searchWithQNA,advanceSearch:this.advanceSearch };
    localStorage.setItem('searchQuery', JSON.stringify(queryParams))
  }
}
  cHeaderComponent_GetLogo(){
    this.oDataService.DataService_GetLoginConfigLogo().subscribe({
      next: (result) => {
        this.showLogo=true
        if(result.status ==="failed")
        {
        }
        else
        {
          // this.logo=result.data?.logo?.logo??'../../../assets/images/logo-text-dark.png';
          // if(result.data.logo)this.sUIService.updateFavicon('data:image/png;base64,'+result?.data?.logo?.logo)
        }
      },
      error: (error) => {
        this.showLogo=true
        this.logo='../../../assets/images/logo-text-dark.png';
        console.log("GroupsComponent_GetUsersList : Create user error==>", error);
      },
      complete: () => { },
    })
  }
  HeaderComponent_OpenEditprofile(editProfile){
    this.oModalService.open(editProfile,  { centered: true ,backdrop : 'static', size: 'md' });
    this.HeaderComponent_InitializeChangeNameForm();
  }
  HeaderComponent_ChangePassword(changePassword){
    this.oModalService.open(changePassword,  { centered: true ,backdrop : 'static', size: 'md' });
    this.HeaderComponent_InitializeChangePasswordForm();
  }
  HeaderComponent_DismissModal(){
    this.changePasswordForm?.reset();
    this,this.changeNameForm?.reset();
    this.oModalService.dismissAll();
  }
  HeaderComponent_Logout()
  {
    this.oDataService.oGetFolders.next('');
    this.oDataService.oGetDocFolders.next('');
    localStorage.clear();
    this.oDataService.globalSearch.next({folderId:'', searchBoolan : false});
    this.searchDocumentResult = '';
    this.searchWithQNA = false
    this.advanceSearch = false
    this.oRoute.navigate(['/auth/login']);
    localStorage?.removeItem('docState');
    let res={
      searchPhrase:'',
      qNa:false
    }
    this.oDataService.populateGlobalSeacrhField.next(res)
  }
  HeaderComponent_hideHeaderSearch(){
    this.oDataService.globalSearch.next({folderId:'', searchBoolan : false});
    localStorage.removeItem('searchQuery')
    this.searchDocumentResult=''
    this.searchWithQNA=false
    this.advanceSearch = false
    this.collapsed = false
    this.onToggleSidenav.emit({collapsed:this.collapsed, screenWidth:this.screenWidth});
   
  }
  HeaderComponent_NavigateToServices()
  {
    this.oRoute.navigate(['/intro']);
  }
  HeaderComponent_FilterData()
  {
    if(this.searchResult != "")
    {
      this.oRoute.navigate(['/dictionary/search-results'], { queryParams : { searchPhrase : this.searchResult } });
    }
  }
  HeaderComponent_InitializeChangePasswordForm()
  {
    const oPasswordRejex = /^.{8,}$/;
    this.changePasswordForm = this.oFormBuilder.group({
      userId : [this.UserDetails.userId, Validators.required],
      password: ['', [Validators.required, Validators.pattern(oPasswordRejex)]],
      rptPassword: ['', Validators.required],
      oldPassword: ['', [Validators.required, Validators.pattern(oPasswordRejex)]],
    },
    {validator: MustMatch('password', 'rptPassword')}
    )
  }
  HeaderComponent_InitializeChangeNameForm()
  {
    let oTextRejex = /^(?! )(.*)$/;
    this.changeNameForm = this.oFormBuilder.group({
      userId : [this.UserDetails.userId, [Validators.required]],
      firstname: [this.UserDetails.firstName, [Validators.required, Validators.pattern(oTextRejex)]],
      lastname: [this.UserDetails.lastName, [Validators.required, Validators.pattern(oTextRejex)]]
    }
    )
  }
  HeaderComponent_changePassword(){
    if(this.changePasswordForm.valid)
    {
      let body=this.UserDetails
      body.oldPassword=this.changePasswordForm.controls['oldPassword'].value
      body.password=this.changePasswordForm.controls['password'].value
      body.rptPassword=this.changePasswordForm.controls['rptPassword'].value
      this.oDataService.DataService_ChangePassword(body).subscribe({
      next: (result) => {
       if(result.failed)
       {
        this.HeaderComponent_displayAlertMessage(result.message,'failed','danger');
       }
       else
       {
        this.oModalService.dismissAll();
        this.changePasswordForm.reset();
        this.HeaderComponent_displayAlertMessage('Success','success','success');
        setTimeout(() => {
          this.HeaderComponent_Logout()
        }, 2000);
       }
      },
      error: (error) => {
        this.HeaderComponent_displayAlertMessage( error.message=='null'?'error':error.message,'failed','danger');
      },
      complete: () => { },
    }
    )
    }
    else
    {
      this.changePasswordForm.markAllAsTouched()
    }
  }
  HeaderComponent_changeName(){
    if(this.changeNameForm.valid)
    {
      let body=JSON.parse(JSON.stringify(this.UserDetails))
      body.firstName=this.changeNameForm.controls['firstname'].value
      body.lastName=this.changeNameForm.controls['lastname'].value
      body.fullName=body.firstName+" "+body.lastName
      this.oDataService.DataService_EditUserProfile(body).subscribe({
      next: (result) => {
       if(result.failed)
       {
        if(result?.namedMessages)this.HeaderComponent_displayAlertMessage(result.namedMessages[0].message,'failed','danger');
        else{this.HeaderComponent_displayAlertMessage(result.detailMessage,'failed','danger');}
       }
       else
       {
        this.oModalService.dismissAll();
        this.changeNameForm.reset();
        this.HeaderComponent_displayAlertMessage('Success','success','success');
        this.UserDetails = result.props.user
        this.oSessionService.SessionService_SetUserDetails(result.props.user)
       }
      },
      error: (error) => {
        this.HeaderComponent_displayAlertMessage( error.message,'failed','danger');
      },
      complete: () => { },
    }
    )
    }
    else{
      this.changeNameForm.markAllAsTouched()
    }
  }
  // -------------------- ALERT MESSAGES --------------------
  HeaderComponent_displayAlertMessage(sIncommingMessage, sIncommingResponseType,sIncommingColor) {
    this.bDisplayErrorBlock = true
    this.resMessage = 
    {
      message: sIncommingMessage,
      responseType : sIncommingResponseType,
      color :  sIncommingColor
    };
    setTimeout(() => { this.bDisplayErrorBlock = false; }, 3000);
  }
  HeaderComponent_GoTopreviousPage()
  {
    let link = this.oRoute.url;
    const questionMarkIndex = link.indexOf("?");
    const extractedString = questionMarkIndex !== -1 ? link.substring(0, questionMarkIndex + 1) : link;
    let menus = this.oSessionService.SessionService_GetApplicationSideBarConfigurationFile().menus;
    switch (extractedString) {
      case '/doc-manager/results?':
      localStorage.removeItem('searchQuery')
      this.searchDocumentResult = ''
      this.searchWithQNA=false;
      this.advanceSearch=false;
      this.oRoute.navigate(['/doc-manager'])
      break;
      case '/doc-manager':
      this.searchDocumentResult = ''
      this.searchWithQNA=false
      this.advanceSearch=false;
      localStorage.removeItem('searchQuery')
      this.oDataService.clickLogo.next(true);
      break;
      }
  }
  HeaderComponent_RouteBackToMenu(MenuItems){
      if(MenuItems[0].routerLink=='/filemanager')
      {
        if (MenuItems && MenuItems.length > 0) {
          for (let i = MenuItems.length - 1; i >= 0; i--) {
            const menuItem = MenuItems[i];
            if (menuItem.routerLink) {
              this.oRoute.navigate([menuItem.routerLink]);
              return;
            }
          }
      }
      }
      else
      {
        this.oRoute.navigate([MenuItems[0]?.routerLink])
      }
}
  HeaderComponent_ToggleCollapse()
  {
    this.bSplitArea = !this.bSplitArea;
  }
  HeaderComponent_NavigateToSearchResults()
  {
    let docState=JSON.parse(localStorage.getItem('docState'));
    if(this.searchDocumentResult != '')
    {
      let queryParams = { search : this.searchDocumentResult, qna : this.searchWithQNA ,advanceSearch: this.advanceSearch };
      localStorage.setItem('searchQuery', JSON.stringify(queryParams))
      this.selectedDocumentFolderId != '' && docState!=null && docState.documents   ? queryParams['folderId'] = this.selectedDocumentFolderId : delete queryParams['folderId'];
      this.oRoute.navigate(['/doc-manager/results'], { queryParams });
    }
  }
  HeaderComponent_CheckQNA(event)
  {
    this.searchWithQNA = event.target.checked;
    let queryParams = { search : this.searchDocumentResult, qna : this.searchWithQNA,advanceSearch: this.advanceSearch  };
    localStorage.setItem('searchQuery', JSON.stringify(queryParams))
  }
  HeaderComponent_CheckAdvanceSearch(event)
  {
    this.advanceSearch = event.target.checked;
    let queryParams = { search : this.searchDocumentResult, qna : this.searchWithQNA,advanceSearch: this.advanceSearch  };
    localStorage.setItem('searchQuery', JSON.stringify(queryParams))
  }
  HeaderComponent_TenantInformation(oIncomingModal)
  {
    this.oDataService.DataServiceGetTenantInformation().subscribe({
      next: (result) => {
       if(result.failed)
       {
        this.HeaderComponent_displayAlertMessage( result.message,'failed','danger');
       }
       else
       {
         this.loginTenantInformation = result;
         this.oModalService.open(oIncomingModal,  { centered: true ,backdrop : 'static', size: 'md' });
       }
      },
      error: (error) => {
        this.HeaderComponent_displayAlertMessage( error.message,'failed','danger');
      },
      complete: () => { },
    }
    )
  }
  HeaderComponent_VersionInformation(oIncomingModal)
  {
    this.oDataService.DataService_GetWBVersion().subscribe({
      next: (result) => {
       if(result.failed)
       {
        this.HeaderComponent_displayAlertMessage( result.message,'failed','danger');
       }
       else
       {
         this.versionInformation =  this.removeSpacesInKeys(result);
         const apiDateTime = new Date(this.versionInformation.BuildDate);
         // Format the date portion
        const formattedDate = apiDateTime.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        });

        // Format the time portion in 12-hour format with AM/PM
        const formattedTime = apiDateTime.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true, // Use 12-hour format with AM/PM
        });
         const formattedDateTime = `${formattedDate} ${formattedTime}`;
         this.versionInformation.BuildDate = formattedDateTime
         this.oModalService.open(oIncomingModal,  { centered: true ,backdrop : 'static', size: 'md' });
       }
      },
      error: (error) => {
        this.HeaderComponent_displayAlertMessage( error.message,'failed','danger');
      },
      complete: () => { },
    }
    )
  }
  removeSpacesInKeys(obj) {
    const newObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = key.replace(/ /g, ''); 
        newObj[newKey] = obj[key];
      }
    }
    return newObj;
  }
  SidebarComponent_ToggleCollapse()
  {
    this.collapsed = !this.collapsed
    this.onToggleSidenav.emit({collapsed:this.collapsed, screenWidth:this.screenWidth});

  }
}
