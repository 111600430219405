<app-header [isSideNavCollapsed]="isSideNavCollapsed"  (onToggleSidenav)="onToggleSidenav($event)"></app-header>
<app-sidebar  [isSideNavCollapsed]="isSideNavCollapsed" (onToggleSidenav)="onToggleSidenav($event)" ></app-sidebar>

<div class="body" [class.body-trimmed]="userDetails.role !== 'admin'"
[class.m-0]="userDetails?.role == 'admin'"

>
    <div class="position-relative">
        <router-outlet></router-outlet>
    </div>
</div>

<div *ngIf="isSideNavCollapsed && check && userDetails.role == 'admin'" class="admin-overlay" (click)="dismissOverlay()"></div>