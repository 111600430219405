<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed':''" [class.sidenav-collapsed-admin]="userDetails.role == 'admin'">
  
    <ul class="sidenav-nav">
        <li class="sidenav-nav-item" *ngFor="let item of navData"  [ngClass]="item.label == 'Separator' ? 'custom-seperator':''">
            <a  class="sidenav-nav-link" [class.justify-content-center]="!collapsed" [ngbTooltip]="!collapsed ? item.label: ''" placement="right"
            (click)="SidebarComponent_CloseSidebar(item.routerLink)" [routerLink]="item.routerLink" routerLinkActive="active" *ngIf="item.label != 'Separator'">
                <i class="sidenav-link-icon fa-light" [class]="item.icon"></i>
                <span class="sidenav-link-text" *ngIf="collapsed">{{item.label}}</span>
            </a>
        </li>
    </ul>
</div>