import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BusyLoaderService } from './services/busyloader.service';
import { cDataService } from './services/data.service';
import { UiService } from './services/ui.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'saas';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(private loaderService: BusyLoaderService,private sUIService : UiService,@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, private oDataService : cDataService ,private oCheckIdleState: Idle, private keepalive: Keepalive, private oRouter : Router) 
  {
    if(environment.Theme == 'kidm'){
      this.renderer.addClass(document.body,environment.Theme)
    }
    this.loaderService.httpProgress().subscribe((status: boolean) => {
      let docState = JSON.parse(localStorage.getItem('docState'));
      if (status) {
        this.renderer.addClass(document.body, 'loading');
        this.renderer.removeClass(document.body, 'with-ellipses');
        this.renderer.removeClass(document.body, 'with-dashboard');
      }
      else {
        this.renderer.removeClass(document.body, 'loading');
        this.renderer.removeClass(document.body, 'with-ellipses');
        this.renderer.removeClass(document.body, 'with-dashboard');
      }
      if (status && this.oRouter.url == '/usermanager' || this.oRouter.url == '/filemanager' || this.oRouter.url == '/usermanager/roles' || this.oRouter.url.includes('/doc-manager/results') )
      {
        this.renderer.addClass(document.body, 'with-ellipses');
      }
        if(status && this.oRouter.url == '/doc-manager' && docState?.documents && !docState?.bulkUpload )
        {
          this.renderer.addClass(document.body, 'with-dashboard');
        }
        if(status && this.oRouter.url == '/doc-manager' && (docState?.documents && docState?.bulkUpload || !docState?.documents && !docState?.bulkUpload) )
        {
          this.renderer.addClass(document.body, 'with-ellipses');
        }
    });
  }
  ngOnInit(): void {
    this.oDataService.SetApplicationOnRememberCheck.subscribe(result => {
      let rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      let TimeOutForApplication : number = result;
      rememberMe == true ? TimeOutForApplication = 604800 : TimeOutForApplication = 14400;
      this.AppComponent_SetSessionExpiryTime(TimeOutForApplication);
    })

    const currentURL = this.document.location.origin;
    switch (currentURL) {
      case 'https://tfm-1695437972.trilloapps.com':
        this.sUIService.updateTitle("File Manager");
        break;
      default:
        this.sUIService.updateTitle("Trillo Workbench");
        break;
    }
  }
  AppComponent_SetSessionExpiryTime(oIncomingTime)
  {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.oCheckIdleState.setIdle(5);
    this.oCheckIdleState.setTimeout(oIncomingTime);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.oCheckIdleState.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.oCheckIdleState.onIdleEnd.subscribe(() => 
    {
      this.idleState = 'No longer idle.'
      console.log("On idle end Idle state : ==> ", this.idleState);
    });
    this.oCheckIdleState.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log("On Time out Idle state : ==> ", this.idleState);
      localStorage.clear();
      this.oRouter.navigateByUrl('/auth/login');
      this.AppComponent_ResetTimer();
    });
    this.oCheckIdleState.onIdleStart.subscribe(() => 
    {
      this.idleState = 'You\'ve gone idle!'
      console.log("On idle start Idle state : ==> ", this.idleState);
    });
    this.oCheckIdleState.onTimeoutWarning.subscribe((countdown) => 
    {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    //  console.log("on Timeout warning Idle state : ==> ", this.idleState);
    });

    // sets the ping interval to 5 seconds
    this.keepalive.interval(5);
    this.keepalive.onPing.subscribe(
      () => 
      {
        let TimeOutForApplication = oIncomingTime;
        let rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
        rememberMe == true ? TimeOutForApplication = 604800 : TimeOutForApplication = 14400;
        this.lastPing = new Date();
        const nSessionExpiryTime = this.lastPing.getTime() + TimeOutForApplication*1000;
        localStorage.setItem("lsTrilloWorkbenchSaasExpiryTime",JSON.stringify(nSessionExpiryTime))
      });

    this.AppComponent_ResetTimer();
  }
  AppComponent_ResetTimer() 
  {
    this.AppComponent_CheckSessionExpiry()
    this.oCheckIdleState.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    console.log("AppComponent_ResetTimer Called!!");
  }

  AppComponent_CheckSessionExpiry() {
    const oDate = new Date();
    const nSessionExpiryTime = JSON.parse(localStorage.getItem("lsTrilloWorkbenchSaasExpiryTime"));
    const nCurrentTime = oDate.getTime();
    if (nSessionExpiryTime !== null) {
      // compare the expiry time of the item with the current time
      if (nCurrentTime > nSessionExpiryTime) {
        localStorage.removeItem("lsTrilloWorkbenchSaasExpiryTime");
        localStorage.clear();
        console.log("Session_CheckSessionExpiry: Current Session is expired. So, logging out");
        this.oRouter.navigateByUrl('/auth/login');
      }
    }
  }
}
