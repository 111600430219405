import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusyLoaderService } from 'src/app/services/busyloader.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class cLoaderInterceptor implements HttpInterceptor {

  private count = 0;

  constructor(private loaderService: BusyLoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.count === 0) {
      if(req.url.includes('_preauthsvc') || req.url.includes('GetLogo'))
      {
      this.loaderService.setHttpProgressStatus(false);
      
      }
      else
      {
      this.loaderService.setHttpProgressStatus(true);

      }
    }
    this.count++;
    return next.handle(req).pipe(
      finalize(() => {
        this.count--;
        if (this.count === 0) {
          this.loaderService.setHttpProgressStatus(false);
        }
      }));
  }
}