import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { cDataService } from 'src/app/services/data.service';
import { cSessionService } from 'src/app/services/session.service';
import { SidebarService } from './sidebar.service';
interface RouteInfo {
  routerLink: string;
  icon: string;
  label: string;
}

interface SideNavToggle{
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnChanges{
@Output() onToggleSidenav : EventEmitter<SideNavToggle> = new EventEmitter();
  collapsed: boolean = false;
  public navData: RouteInfo[] = [];
  SidebarList : any =[]
  lConfigurations: any = [];
  screenWidth = 0
  @Input() isSideNavCollapsed;
  userDetails: any;
  constructor(private menuServise: SidebarService,private oDataService : cDataService , private cSessionsService : cSessionService){

  }
  ngOnInit(): void {
    let lConfig = this.cSessionsService.SessionService_GetApplicationSideBarConfigurationFile();
    this.lConfigurations = lConfig?.menus;
    this.VerticalSidebarComponent_DisplayMenuItemBasedOnUserRole();
   
    this.userDetails = this.cSessionsService.SessionService_GetUserDetails();
    if(this.userDetails.role !== 'admin')this.collapsed = true
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.userDetails?.role =='admin') this.collapsed = this.isSideNavCollapsed;

  }
  VerticalSidebarComponent_DisplayMenuItemBasedOnUserRole()
  {
    var lMenuItems = [];
    this.navData=[];
    this.menuServise.items.subscribe(async menuItems =>{lMenuItems = menuItems;});
    for (let nConfigurationItemIndex = 0;nConfigurationItemIndex < this.lConfigurations.length;nConfigurationItemIndex++) 
    {
      let element = this.lConfigurations[nConfigurationItemIndex];
      for(let nMenuItemToPushIndex = 0; nMenuItemToPushIndex < lMenuItems.length; nMenuItemToPushIndex++)
      {
        let item = lMenuItems[nMenuItemToPushIndex];
        if(element.routerLink === item.routerLink)
        {
          this.navData.push(item);
          break;
        }
      }
    }
  }
  SidebarComponent_CustomizingtheSideBar(oIncomingObject)
  {
    let dataBaseObject : any = {database : false,bucket:false};
    dataBaseObject.database = oIncomingObject.database?oIncomingObject.database:false;
    dataBaseObject.bucket = oIncomingObject.bucket?oIncomingObject.bucket:false;
    if(dataBaseObject?.bucket == true && dataBaseObject?.database == false)
    {
      this.SidebarList = [];
      this.navData.forEach(element => {if(element.label == 'File Manager' || element.label == 'Dashboard')this.SidebarList.push(element)});
    }
    else if(dataBaseObject?.database == true && dataBaseObject?.bucket == false)
    {
      this.SidebarList = [];
      this.navData.forEach(element => {if(element.label != 'File Manager')this.SidebarList.push(element)});
    }
    else if(dataBaseObject?.bucket == true && dataBaseObject?.database == true)
    {
      this.SidebarList = [];
      this.navData.forEach(element => {this.SidebarList.push(element)})
    }
  }
  SidebarComponent_CloseSidenav()
  {
    this.collapsed = false
    this.onToggleSidenav.emit({collapsed:this.collapsed, screenWidth:this.screenWidth});

  }
  SidebarComponent_CloseSidebar(incomingRouter)
 {
  if(this.userDetails.role== 'admin') this.SidebarComponent_CloseSidenav()
  if(incomingRouter!='/doc-manager'){
     this.oDataService.globalSearch.next({folderId:'', searchBoolan : false});
     this.oDataService.resetSearch.next(true)
    }
 }
}
